import React, { useMemo } from 'react';
import { Carousel } from 'antd';
import styled from '@emotion/styled';

const StyledCarousel = styled(Carousel)`
  display: flex;
  text-align: center;
  overflow: hidden;

  .slick-list {
    display: flex;
    flex-direction: column;
    vertical-align: center;
  }

  .slick-dots {
    display: none !important;
  }

  @media (min-width: 640px) {
    .slick-dots {
      display: block !important;
      margin: 0;
      bottom: unset;
      transform: scale(2);
    }
    .slick-dots li button {
      background-color: var(--productPrimary);
    }
    .slick-dots li.slick-active button {
      background-color: var(--productPrimary);
    }
  }
`;

const StyledH1 = styled.h1`
  @media (min-width: 640px) {
    margin-top: 25px;
  }
`;

const StyledUl = styled.ul`
  list-style: none;
  padding: 0 1em;
`;

const BulletPoint = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  text-align: left;

  ::before {
    content: '✓';
    font-family: system-ui;
    font-weight: 400;
    font-size: 1.5em;
    margin-right: 0.5em;
    display: flex;
    align-items: center;
  }
`;

const SLIDE_SIZE = 3;

const getBulletPoints = (html: string): string[][] => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');

  const ul = doc.querySelector('ul');
  if (!ul) {
    return [];
  }

  const allBulletPoints = Array.from(ul.querySelectorAll('li')).map((li) => li.innerHTML);
  return allBulletPoints.reduce<string[][]>((slides, _, i) => {
    if (i % SLIDE_SIZE === 0) {
      slides.push(allBulletPoints.slice(i, i + SLIDE_SIZE));
    }
    return slides;
  }, []);
};

export const WelcomeCarousel = ({ articleTitle, articleBody }: { articleTitle: string; articleBody: string }) => {
  const allBulletPointsSplit = useMemo(() => getBulletPoints(articleBody), [articleBody, articleTitle]);

  return (
    <StyledCarousel autoplay dotPosition="top" autoplaySpeed={20000}>
      {allBulletPointsSplit.map((slide, i) => (
        <div key={i}>
          <StyledH1>{articleTitle}</StyledH1>
          <StyledUl>
            {slide.map((bulletPoint, i) => (
              <BulletPoint dangerouslySetInnerHTML={{ __html: bulletPoint }} key={i} />
            ))}
          </StyledUl>
        </div>
      ))}
    </StyledCarousel>
  );
};
