import React from 'react';
import Header from '@components/Layouts/Header/Header';
import useArticle from '@hooks/useArticle';
import { WelcomeCarousel } from '@components/Login/WelcomeCarousel';
import styled from '@emotion/styled';

const LoginHeader = styled(Header)`
  border-bottom: 1px solid var(--productIron);
`;

const LoginSection = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: 450px;
  padding: 1em 1em 150px 1em;

  .ant-form-item-required {
    font-size: 16px;
  }

  .ant-form-item-extra,
  .ant-form-item-explain {
    margin-top: 5px;
  }

  .ant-input-affix-wrapper {
    padding: 0 11px;
  }

  .ant-input {
    height: 3em;
    font-size: 16px;
  }

  .login-alert {
    margin: 0 0 20px;
  }
`;

const LoginCarouselContainer = styled.section`
  max-width: 450px;
  margin: 0 auto 1rem;
`;

const LoginBanner = styled.section`
  background-image: url('/static/svg/login-background.svg');
  background-size: cover;
  background-position: 50% 100%;
  background-repeat: no-repeat;

  height: 100px;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 100%;
    margin: 0 auto;
    padding: 1em;
  }

  @media (min-width: 640px) {
    height: 150px;
  }

  @media (min-width: 768px) {
    height: 200px;
  }

  @media (min-width: 1024px) {
    margin-bottom: -150px;
    padding-bottom: 150px;

    height: 300px;
  }

  @media (min-width: 1280px) {
    height: 350px;
  }
`;

const LoginLayout: React.FC<{
  children: React.ReactNode;
  title: string;
  showSidebar?: boolean;
  sidebarArticle?: string;
}> = ({ children, title, showSidebar = true, sidebarArticle = 'services' }) => {
  const article = useArticle(sidebarArticle, true);

  return (
    <div>
      <LoginHeader pageType="login" className="login-header" pageTitle={title} />

      <LoginBanner>
        <img src="/static/svg/login-illustration.svg" alt="" />
      </LoginBanner>

      {article && showSidebar && (
        <LoginCarouselContainer>
          <WelcomeCarousel articleTitle={article.title} articleBody={article.body} />
        </LoginCarouselContainer>
      )}

      <LoginSection>{children}</LoginSection>
    </div>
  );
};

export default LoginLayout;
